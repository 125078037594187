<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import router from "@/router/index";
import { GetEnergyCompassData } from "@/services/api/chart.api";
import energyCompassImage from "@/assets/img/energiekompas.png";

const props = defineProps({
  token: undefined,
  refDate: undefined,
});

const canv = ref();
const points = ref();
const currentPoint = ref(null);

const labelColors = ["#e51c20", "#eb5d19", "#f6a210", "#fce510", "#a6c92b", "#53b039", "#029246", "#029246", "#029246", "#029246", "#029246"];
const labelLabels = ["G", "F", "E", "D", "C", "B", "A", "A+", "A++", "A+++", "A++++", "A+++++"];

const categoryCutoffs = [108, 100, 60, 35, 25, 18, 0, -14];
const categoryColors = ["#ff0303", "#d90000", "#b21d45", "#b21d9c", "#821d9c", "#431d9c", "#002f73"];
const categoryLabels = ["zeer onzuinig", "onzuinig", "gemiddeld", "zuinig", "zeer zuinig", "Paris Proof", "WENG"];

const margin = 5; // margin between boxes (double margin between cat & lbl boxes)
const dotSize = 8;

watch(
  () => points.value,
  () => {
    redraw();
  },
  { immediate: false }
);

watch(
  () => props.token,
  () => {
    fetchData();
  },
  { immediate: true }
);

async function fetchData() {
  let response = await GetEnergyCompassData(props.token);
  //console.log("EnergyCompassData watch token", response);
  let newPoints = response.data.items;
  if (newPoints == null) {
    newPoints = [];
  } else {
    for (let i = 0; i < newPoints.length; i++) {
      newPoints[i].x = newPoints[i].normalizedEPOnlineLabel;
      newPoints[i].y = newPoints[i].normalizedParisProofScore;
    }
  }
  points.value = newPoints;
}

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

function handleResize() {
  canv.value = document.getElementById("energyCompassCanvas");
  if (!canv.value) return;
  redraw();
}

async function doClick() {
  if (currentPoint.value) {
    router.push({ name: "BuildingDetails", params: { addressId: currentPoint.value.paoId } });
  }
  /*
      // Het punt bij de actuele tooltip zit in currentPoint, en daarmee
      // kunnen we de URL voor de gebouw- of complex-detail pagina construeren.
      let newPoints = [];
      for (let i = 0; i < 20; i++) {
        let x = Math.random() * 10;
        let y = Math.random() * 132 - 19;
        let point = { x: x, y: y, description: "point " + i };
        newPoints.push(point);
      }
      points.value = newPoints;
      let dummy = await GetEnergyCompassData(props.token);
      console.log(dummy);
      */
}

function doMove(event) {
  if (!points.value) {
    doLeave();
    return;
  }

  let rect = event.target.getBoundingClientRect();
  let mx = event.clientX - rect.left;
  let my = event.clientY - rect.top;

  let w = canv.value.clientWidth;

  let h = canv.value.clientHeight;
  let s = (w - margin) / 13; // box size (category box is double width)
  let cscale = (h - s - margin) / (categoryCutoffs[0] - categoryCutoffs[7]);

  let foundIndices = [];
  for (let i = points.value.length - 1; i >= 0; i--) {
    let point = points.value[i];
    let x = point.x * s + (2 * s + (s + margin) / 2);
    let yy = 0;
    if (point.y < 0) {
      yy = dotSize;
    } else if (point.y > categoryCutoffs[0]) {
      yy = categoryCutoffs[0] - dotSize;
    } else {
      yy = point.y;
    }
    let y = (categoryCutoffs[0] - yy) * cscale;
    if (Math.hypot(mx - x, my - y) <= dotSize) {
      foundIndices.push(i);
    }
  }

  if (foundIndices.length == 0) {
    doLeave();
    return;
  }

  let tooltip = document.getElementById("energyCompassToolTip");
  tooltip.style.display = "block";
  tooltip.style.left = event.pageX + "px";
  tooltip.style.top = event.pageY - 40 + "px";
  tooltip.innerHTML = "";

  for (let i = 0; i < foundIndices.length; i++) {
    tooltip.innerHTML += points.value[foundIndices[i]].description;
    tooltip.innerHTML += "<br/>";
    tooltip.innerHTML += "Paris proof score: " + points.value[foundIndices[i]].parisProofScore.toFixed(0) + " kWh/m²";

    if (i < foundIndices.length - 1) {
      tooltip.innerHTML += "<br/><br/>";
    }
  }
  currentPoint.value = points.value[foundIndices[0]];
}

function doLeave() {
  let tooltip = document.getElementById("energyCompassToolTip");
  tooltip.style.display = "none";
  currentPoint.value = null;
}

function redraw() {
  let ctx = canv.value.getContext("2d");
  let w = canv.value.clientWidth;
  let h = canv.value.clientHeight;
  canv.value.width = w;
  canv.value.height = h;

  let categoryFont = "bold 10px arial";

  let s = (w - margin) / 13; // box size (category box is double width)

  let cscale = (h - s - margin) / (categoryCutoffs[0] - categoryCutoffs[7]);

  // achtergrond-rechthoek, nog zonder rand
  let x1 = 2 * s;
  let x2 = w;
  let y1 = 0;
  let y2 = (categoryCutoffs[0] - categoryCutoffs[7]) * cscale;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x1, y2);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x2, y1);
  ctx.closePath();
  ctx.fillStyle = "#f2f2f2";
  ctx.fill();

  //Ideal line
  ctx.beginPath();
  ctx.lineWidth = 3;
  ctx.moveTo(x1, y1 + (h / 100) * 44);
  ctx.lineTo(x2, y2 - (h / 100) * 18);
  ctx.strokeStyle = "#d0d0d0";
  ctx.stroke();
  ctx.lineWidth = 1;

  for (let i = 0; i < 7; i++) {
    //WEii labels
    let bx = margin;
    let by = (categoryCutoffs[0] - categoryCutoffs[i]) * cscale + margin;
    let bw = 2 * (s - margin);
    let bh = (categoryCutoffs[i] - categoryCutoffs[i + 1]) * cscale - margin;
    roundRect(ctx, bx, by - margin / 2, bw, bh, margin);
    ctx.fillStyle = categoryColors[i];
    ctx.fill();
    ctx.fillStyle = "#ffffff";
    ctx.font = categoryFont;
    centeredText(ctx, categoryLabels[i], bx + bw / 2, by + (bh - margin) / 2);
    if (i > 0) {
      //horizontal lines
      ctx.beginPath();
      ctx.moveTo(x1, by - margin);
      ctx.lineTo(x2, by - margin);
      ctx.strokeStyle = "#d0d0d0";
      ctx.stroke();
    }
  }

  //Energiekompas image
  let image = new Image();
  image.src = energyCompassImage;
  image.onload = function () {
    ctx.drawImage(image, 130, 25, 183, 53);
  };

  for (let i = 0; i < 11; i++) {
    //Energy labels
    let bx = (i + 2) * s + margin;
    let by = h - s;
    let bw = s - margin;
    let bh = s - margin;
    roundRect(ctx, bx, by, bw, bh, margin);
    ctx.fillStyle = labelColors[i];
    ctx.fill();
    ctx.fillStyle = "#ffffff";
    centeredLabel(ctx, labelLabels[i], bx + bw / 2, by + bh / 2, "24px arial", "bold 10px arial");
  }

  // de puntenwolk
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x1, y2);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x2, y1);
  ctx.closePath();
  ctx.save();
  ctx.clip();

  if (points.value) {
    for (let i = 0; i < points.value.length; i++) {
      let point = points.value[i];
      let x = point.x * s + (2 * s + (s + margin) / 2);
      let shape = 0;
      let yy = 0;
      if (point.y < 0) {
        yy = 0;
        shape = 1;
      } else if (point.y > categoryCutoffs[0]) {
        yy = categoryCutoffs[0];
        shape = 2;
      } else {
        yy = point.y;
      }
      let y = (categoryCutoffs[0] - yy) * cscale;
      ctx.beginPath();
      switch (shape) {
        case 0:
          ctx.arc(x, y, dotSize, 0, 2 * Math.PI, false);
          break;
        case 1:
          ctx.moveTo(x, y);
          ctx.lineTo(x - dotSize * 1.2, y - dotSize * 1.8);
          ctx.lineTo(x + dotSize * 1.2, y - dotSize * 1.8);
          ctx.closePath();
          break;
        case 2:
          ctx.moveTo(x, y);
          ctx.lineTo(x - dotSize * 1.2, y + dotSize * 1.8);
          ctx.lineTo(x + dotSize * 1.2, y + dotSize * 1.8);
          ctx.closePath();
          break;
      }
      let colorIndex = Math.floor(point.x + 0.5);
      if (colorIndex >= 0 && colorIndex < 11) ctx.fillStyle = labelColors[colorIndex];
      else ctx.fillStyle = "#000000";
      ctx.fill();
    }
  } else {
    ctx.fillStyle = "#000000";
    centeredText(ctx, "Geen\xa0gegevens\xa0gevonden", (w + s) / 2, (categoryCutoffs[0] + categoryCutoffs[7]) / 2);
  }

  ctx.restore();

  // rand van achtergrond-rechthoek
  // boven- en rechter-rand 0.5 pixel naar binnen geschoven zodat ze niet half worden geclipt
  ctx.beginPath();
  ctx.moveTo(x1, y1 + 0.5);
  ctx.lineTo(x1, y2);
  ctx.lineTo(x2 - 0.5, y2);
  ctx.lineTo(x2 - 0.5, y1 + 0.5);
  ctx.closePath();
  ctx.strokeStyle = "#3466c0";
  ctx.lineWidth = 1;
  ctx.stroke();

  // Klaar met de clip
}

function roundRect(ctx, x, y, w, h, r) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + w, y, x + w, y + h, r);
  ctx.arcTo(x + w, y + h, x, y + h, r);
  ctx.arcTo(x, y + h, x, y, r);
  ctx.arcTo(x, y, x + w, y, r);
  ctx.closePath();
}

function centeredText(ctx, text, x, y) {
  let leading = 3;
  let ascent = 0;
  let totalHeight = -leading;
  let comps = text.split(" ");
  let sizes = [];
  for (let i = 0; i < comps.length; i++) {
    let ts = ctx.measureText(comps[i]);
    let h = ts.actualBoundingBoxAscent + ts.actualBoundingBoxDescent + leading;
    sizes.push([ts.width, h]);
    totalHeight += h;
    ascent = ts.actualBoundingBoxAscent;
  }
  let yy = y - totalHeight / 2 + ascent;
  for (let i = 0; i < comps.length; i++) {
    ctx.fillText(comps[i], x - sizes[i][0] / 2, yy);
    yy += sizes[i][1];
  }
}

function centeredLabel(ctx, text, x, y, f1, f2) {
  let letter = text.substring(0, 1);
  let suffix = text.substring(1);
  ctx.font = f1;
  let ts1 = ctx.measureText(letter);
  let lh = ts1.actualBoundingBoxAscent + ts1.actualBoundingBoxDescent;
  let loffset = 0;
  if (suffix != "") {
    ctx.font = f2;
    let ts2 = ctx.measureText(suffix);
    loffset = ts2.actualBoundingBoxAscent + ts2.actualBoundingBoxDescent + 5;
    ctx.fillText(suffix, x - ts2.width / 2, y - (lh + loffset) / 2 + ts2.actualBoundingBoxAscent);
    ctx.font = f1;
  }
  ctx.fillText(letter, x - ts1.width / 2, y - (lh + loffset) / 2 + loffset + ts1.actualBoundingBoxAscent);
}
</script>

<template>
  <v-layout class="widget-item">
    <header>
      <h2>Energiekompas</h2>
      <div class="widget-actions">
        <information-dropdown>
          <template v-slot:header>
            <h2>Energiekompas</h2>
          </template>
          <p>
            Het EnergieKompas combineert de krachten van beschikbare middelen en methodes (het energielabel en de WEii-score), en geeft in één oogopslag inzicht in de verduurzamingsroute van een pand.
            Wat is de meest effectieve route om gebouwen te verduurzamen tot een Paris Proof niveau? Veel bestaande wet- en regelgeving en beschikbare instrumenten bieden geen eenduidige oplossing.
            Het EnergieKompas wel. Door het energielabel en de WEii-score (werkelijk gemeten energiegebruik) te combineren, maakt het EnergieKompas voor elk gebouw inzichtelijk wat de meest effectieve
            route is om op het gewenste ambitieniveau te komen.
          </p>
        </information-dropdown>
      </div>
    </header>

    <!--<loader-spinner v-if="isLoading === true" :is-loading="isLoading" :min-height="200"></loader-spinner>-->
    <!-- Charts werken niet als ze in een v-if constructie zitten -->
    <!--<div v-show="isLoading === false && chart.any == true">-->
    <div>
      <div class="widget-wrapper">
        <div class="widget-chart measures">
          <p
            id="energyCompassToolTip"
            style="display: none; position: absolute; background-color: white; border-style: solid; border-width: 1px; border-color: black; padding: 1px 5px 1px 5px; z-index: 100"
          >
            a tooltip!
          </p>
          <canvas id="energyCompassCanvas" @click="doClick" @mousemove="doMove" @mouseleave="doLeave" style="width: 100%; height: 384px" />
        </div>
      </div>
    </div>
    <!--
    <div v-show="isLoading === false && chart.any == false">
      <p>Er zijn geen energie-waarden beschikbaar voor dit project</p>
    </div>
    -->
  </v-layout>
</template>
