<script lang="ts" setup>
import { computed, watch, getCurrentInstance, onMounted } from "vue";

import { getBuildingApplied } from "@/services/api/measure.api";
import GridPagination from "@/components/general/GridPagination.vue";
import MeasureKindLogo from "@/components/app/measures/MeasureKindLogo.vue";
import { formatThousandNoFraction, formatEuroNoFraction } from "@/composables/formatNumbers";
import useGrid27 from "@/composables/grid27.js";
import router from "@/router/index";

const { fetchFirstPage, gridData, gridOptions, gridSorting, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  addressId: { type: String, default: null },
  filterData: { type: Array, default: () => [] },
  currentPartyType: { type: String },
});

const _headers = [
  { text: "Maatregel", value: "definition.name", width: "30%", show: true },
  { text: "Code", value: "definition.code", width: "10%", show: true },
  { text: "Investering (€)", value: "investment", align: "right", show: true },
  { text: "Terugverdientijd (jaren)", value: "recoupedYears", align: "right", show: true },
  { text: "Besparing elektra (kWh)", value: "reductionElec", align: "right", show: true },
  { text: "Besparing gas (m³)", value: "reductionGas", align: "right", show: true },
  { text: "Besparing warmte (GJ)", value: "reductionHeat", align: "right", show: true },
  { text: "Besparing CO₂ (kg)", value: "reductionCo2", align: "right", show: true },
  { text: "Status", value: "status.descr", show: true },
  { text: "Zelfstandig moment", value: "definition.isApplicableAtIndependentMoment", show: true },
  { text: "Soort", value: "kind", show: props.currentPartyType != "Homes" },
];
const headers = computed(() => _headers.filter((x) => x.show));

onMounted(() => {
  gridSorting.value = { sortBy: ["reductionCo2"], sortDesc: [true] };
});

watch(
  () => props.filterData,
  () => {
    fetchFirstPage();
  },
  {
    // watch hoeft niet af te gaan bij het initialiseren van het veld, alleen indien het wijzigt
    immediate: true,
  }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("BuildingAppliedGrid getData");
  const pageNumber0 = pageNumber - 1;

  const response = await getBuildingApplied(props.addressId, pageNumber0, pageSize, sortBy, sortDesc, props.filterData);
  console.log("%cBuildingAppliedGrid, getData", "background: purple; color: white", response, sortBy, sortDesc);
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  console.log("BuildingAppliedGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}

async function gotoAppliedDetails(payload) {
  router.push({ name: "AppliedDetails", params: { appliedId: payload.guid } });
}
</script>

<template>
  <div>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      class="overview-table"
      @update:options="changedOptions"
      @click:row="gotoAppliedDetails"
    >
      <template v-slot:[`item.investment`]="{ item }">
        {{ formatEuroNoFraction(item.investment) }}
      </template>
      <template v-slot:[`item.kind`]="{ item }">
        <MeasureKindLogo :kind="item.definition.kind.key" :description="item.definition.kind.descr"></MeasureKindLogo>
      </template>
      <template v-slot:[`item.recoupedYears`]="{ item }">
        <span v-if="item.recoupedYears != null">
          {{ item.recoupedYears.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) }}
        </span>
      </template>
      <template v-slot:[`item.reductionElec`]="{ item }">
        <span v-if="item.reductionElec != null">{{ formatThousandNoFraction(item.reductionElec) }} </span>
      </template>
      <template v-slot:[`item.reductionGas`]="{ item }">
        <span v-if="item.reductionGas != null">{{ formatThousandNoFraction(item.reductionGas) }} </span>
      </template>
      <template v-slot:[`item.reductionHeat`]="{ item }">
        <span v-if="item.reductionHeat != null">{{ formatThousandNoFraction(item.reductionHeat) }} </span>
      </template>
      <template v-slot:[`item.reductionCo2`]="{ item }">
        <span v-if="item.reductionCo2 != null">{{ formatThousandNoFraction(item.reductionCo2) }} </span>
      </template>
      <template v-slot:[`item.definition.isApplicableAtIndependentMoment`]="{ item }">
          {{ item.definition.isApplicableAtIndependentMoment === true ? "Ja" : "Nee" }}
      </template>
    </v-data-table>
    <grid-pagination v-if="totalItems > 5" :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
